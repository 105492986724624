import React from 'react'
import { gql } from '@apollo/client'
import { withApollo } from '@apollo/react-hoc'
import {
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
  Collection_Reports_Set_Input,
  useGetEnforcementRequestsForCollectionReportQuery,
  useGetReportStateChangesQuery,
  useSendSlackMessageMutation,
  useUpdateCollectionReportsWithNotesMutation,
  useUpdateImportanceScoreForReportMutation,
  useUpdateMaxSimilarityScoreForReportMutation,
  useUpdateReportsWithStatusMutation,
} from '../../generated/graphql'
import {
  getOrgData,
  useEmail,
  useIsEmployeeView,
  useOrgID,
  useUserID,
} from '../../hooks/id_token_claims'
import { useSelectedReport } from '../../hooks/report_table_filters'
import {
  getStatusLabel,
  Marketplace,
  ProductType,
  ReportStatus,
  ReportType,
} from '../../utils/constants'
import { isDisneyOrgId, isNftVolumeEnabled } from '../../utils/feature_flags'
import {
  convertCryptoToDollars,
  delayRefetchedQueries,
  convertToDollarAmount,
} from '../../utils/index'
import {
  AssetType,
  extractChainFromNftOrCollection,
  extractCollectionSlugFromNftOrCollection,
  extractMediaSrcFromReport,
  getMatchTypes,
} from '../../utils/reports/match_utils'
import { getTimestampDisplay } from '../../utils/time'
import EnforcementModal from '../enforcements/enforcement_modal'
import DetailNotes from '../shared/detail_notes'
import DoppelScore from '../shared/doppel_score'
import DetailModal from '../shared_reports_table/detail_modal'
import CollectionMarketplaceLinks from './collection_marketplace_links'
import DetailRow from './detail_row'
import DoppelLink from './doppel_link'
import ImagePreviewSection from './image_preview_section'
import MatchSelector from './match_selector'
import MatchFlaggedContentSection from './nft_marketplace_links'
import ReportDetailButton, { getAllowedActionButtons } from './report_detail_button'
import { ReportHistory } from './ReportHistory'
import EnforcementStatuses from '../enforcements/enforcement_statuses'
import { useToast } from '@chakra-ui/react'
import { sendCustomerReportedSlackMessage } from '../../hooks/slack_notifications'
import {
  DOPPEL_CHECKMARK,
  DOPPEL_ALERT_ICON,
  DOPPEL_EDIT_ICON,
  CHAIN_TO_LOGO_URL,
} from '@/utils/image'

const CHAIN_ADDRESSES = {
  eth: 'https://etherscan.io/address/',
  matic: 'https://polygonscan.com/address/',
  flow: 'https://flowscan.org/contract/',
}

export const CHAIN_NAME = {
  matic: 'polygon',
  flow: 'flow',
}

const CounterfeitDetail = ({ handleOnCompletedAction, footerComponent, client }) => {
  const [userId] = useUserID()
  const orgId = useOrgID()
  const [selectedReport, setSelectedReport] = useSelectedReport()
  const [updateReason, setUpdateReason] = useState('')
  const [enforcementModalOpen, setEnforcementModalOpen] = useState(false)
  const [isEnforcementUpload, setIsEnforcementUpload] = useState(false)
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(0)
  const [shouldShowEnforcementDetails, setShouldShowEnforcementDetails] =
    useState(false)

  const [email] = useEmail()
  const [selectedNfts, setSelectedNfts] = useState(new Set<number>())
  const overlayRef = useRef<HTMLInputElement>()
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()

  const { data: enforcementRequestsData, loading: isEnforcementRequestLoading } =
    useGetEnforcementRequestsForCollectionReportQuery({
      variables: { collection_report_id: selectedReport.id },
    })

  const { data: reportStatusChanges, loading: fetchingStateChanges } =
    useGetReportStateChangesQuery({
      variables: {
        id: selectedReport.id,
      },
    })
  const [orgData] = getOrgData()

  const [updateImportanceScoreForReportMutation] =
    useUpdateImportanceScoreForReportMutation()
  const [updateMaxSimilarityScoreForReportMutation] =
    useUpdateMaxSimilarityScoreForReportMutation()

  const [updateReportsWithStatusMutation, _] = useUpdateReportsWithStatusMutation({
    update(cache, { data: { insert_collection_report_state_changes } }) {
      cache.modify({
        fields: {
          collection_report_state_changes(existingChanges) {
            const newStatusChangeRefs =
              insert_collection_report_state_changes.returning.map((stateChange) =>
                cache.writeFragment({
                  data: stateChange,
                  fragment: gql`
                    fragment NewStatusChange on collection_report_state_changes {
                      id
                      new_state
                      collection_report_id
                      previous_state
                      reason_for_change
                      timestamp_of_change
                    }
                  `,
                }),
              )

            return [...existingChanges, ...newStatusChangeRefs]
          },
        },
      })
    },
  })

  const setReportStatus = (status: ReportStatus) => (onCompleted) => {
    const fieldsToUpdate: Collection_Reports_Set_Input = {
      status: status,
    }
    if (status === ReportStatus.RESOLVED) {
      fieldsToUpdate['last_resolved_at'] = new Date().toISOString()
    } else if (status === ReportStatus.REPORTED) {
      fieldsToUpdate['last_reported_at'] = new Date().toISOString()
    }
    updateReportsWithStatusMutation({
      variables: {
        ids: [selectedReport.id],
        state_changes: [
          {
            collection_report_id: selectedReport.id,
            new_state: status,
            previous_state: selectedReport.status,
            reason_for_change: updateReason,
            user_id: userId,
          },
        ],
        fieldsToUpdate: fieldsToUpdate,
      },
      onCompleted: () => {
        onCompleted()
        handleOnCompletedAction(status, selectedReport.status)
        delayRefetchedQueries(client, [
          'CollectionReportsAggregate',
          'CollectionReportsSearchAggregate',
        ])
      },
    })
    if (isEmployeeView) {
      return
    }
    sendCustomerReportedSlackMessage(
      sendSlackMessageMutation,
      email,
      orgData,
      selectedReport.collection_slug,
      selectedReport.id,
      'nfts',
      status,
    )
  }

  const [updateCollectionReportsWithNotesMutation] =
    useUpdateCollectionReportsWithNotesMutation()

  const toast = useToast()

  const onSaveNotes = (notes) => {
    updateCollectionReportsWithNotesMutation({
      variables: {
        ids: [selectedReport.id],
        notes: notes,
        notesChangesInput: {
          type: 'notes',
          collection_report_id: selectedReport.id,
          new_value: notes,
          old_value: selectedReport.notes || '',
          user_id: userId,
          source: 'ui',
        },
      },
      onCompleted: () => {
        toast({
          title: 'Notes saved.',
          description: 'Your notes have been successfully saved.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: 'Error saving notes.',
          description: `Error: ${err.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const counterfeitSrcs = selectedReport.collection_reports_to_matches?.map(
    (match, i) => extractMediaSrcFromReport(selectedReport, i, true),
  )
  const assetSrcs = selectedReport.collection_reports_to_matches?.map((match, i) =>
    extractMediaSrcFromReport(selectedReport, i, false),
  )

  const selectedMatch =
    selectedReport.collection_reports_to_matches.length > selectedMatchIndex
      ? selectedReport.collection_reports_to_matches[selectedMatchIndex].match
      : null
  const firstMatch = selectedReport.collection_reports_to_matches[0].match // TODO: extract multiple match data
  const matchCount =
    selectedReport.collection_reports_to_matches_aggregate.aggregate.count
  const estimatedValue =
    selectedReport.collection_reports_to_matches_aggregate.aggregate.sum.usd_price
  const flaggedCollectioNumItems = selectedReport.flagged_collection?.num_items
  const originalCollectionNumItems = selectedReport.original_collection?.num_items
  const usdVolume =
    selectedReport.collection_reports_to_matches_aggregate?.aggregate?.sum?.usd_volume

  const flaggedCollectionChain = extractChainFromNftOrCollection(
    selectedReport?.flagged_collection,
  )
  const originalCollectionChain = extractChainFromNftOrCollection(
    selectedReport?.original_collection,
  )

  useEffect(() => {
    setUpdateReason('')
    handleUpdateSelectedMatch(0)
    handleUpdateSelectedNfts(true, null, null)
  }, [selectedReport])

  const handleUpdateSelectedMatch = (newIndex) => {
    if (newIndex >= selectedReport.collection_reports_to_matches.length) {
      setSelectedMatchIndex(0)
      return
    }
    setSelectedMatchIndex(newIndex)
  }

  const handleUpdateSelectedNfts = (
    selectAll?: boolean,
    indexToAdd?: number,
    indexToRemove?: number,
  ) => {
    if (selectAll) {
      setSelectedNfts(new Set(counterfeitSrcs.map((m, i) => i)))
      return
    }
    if (indexToAdd != null && indexToAdd < counterfeitSrcs.length) {
      setSelectedNfts(new Set<number>(selectedNfts.add(indexToAdd)))
    } else if (indexToRemove != null) {
      const updatedNfts = new Set(selectedNfts)
      updatedNfts.delete(indexToRemove)
      setSelectedNfts(new Set<number>(updatedNfts))
    }
  }

  const comparisonDividerWidth =
    shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
    originalCollectionHasContractAddress(selectedReport)
      ? '90%'
      : '60%'

  if (enforcementModalOpen) {
    const marketplaceCollections =
      selectedReport?.flagged_collection?.marketplace_collections.map(
        (mc) => mc.marketplace.name.toLowerCase() as Marketplace,
      )

    return (
      <DetailModal
        handleClickOutside={() => {
          setSelectedReport(null)
        }}
        overlayRef={overlayRef}
      >
        <EnforcementModal
          onClose={() => setEnforcementModalOpen(false)}
          onCompleted={() => {
            // TODO: Remove this OnCompleted function dependency (empty func rn)

            if (!isEnforcementUpload) {
              setReportStatus(ReportStatus.REPORTED)(() => {
                // no-op
              })
            }
            handleOnCompletedAction()
            setEnforcementModalOpen(false)
          }}
          platformOptions={marketplaceCollections}
          productType={ProductType.NFT}
        />
      </DetailModal>
    )
  }

  return (
    <>
      <Flex
        direction="column"
        maxWidth={[
          '600px',
          '600px',
          '600px',
          '600px',
          shouldShowRightColumn(selectedReport, selectedMatch?.asset)
            ? '800px'
            : '500px',
        ]}
        padding="24px"
        paddingRight="0px"
        width={[
          '600px',
          '600px',
          '600px',
          '600px',
          shouldShowRightColumn(selectedReport, selectedMatch?.asset) ? '45vw' : '35vw',
        ]}
      >
        <DetailRow
          leftDetail={
            <ImagePreviewSection
              backup_image_url={counterfeitSrcs[selectedMatchIndex]?.backup_image_url}
              icon_url={DOPPEL_ALERT_ICON}
              imageUrl={counterfeitSrcs[selectedMatchIndex]?.image_url}
              is_flagged={true}
              selectedMatchCoordinates={selectedMatch?.model_coordinates}
              subtitle={_extractFlaggedSubtitle(selectedReport)}
              title={extractFlaggedTitle(selectedReport)}
            />
          }
          rightDetail={
            shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
            hasOriginalImage(assetSrcs) && (
              <ImagePreviewSection
                asset_type={selectedMatch?.asset?.type}
                backup_image_url={assetSrcs[selectedMatchIndex]?.backup_image_url}
                icon_url={DOPPEL_CHECKMARK}
                imageUrl={assetSrcs[selectedMatchIndex]?.image_url}
                is_flagged={false}
                subtitle={_extractOriginalSubtitle(
                  selectedReport,
                  selectedMatch?.spoofing_entity,
                )}
                title={_extractOriginalTitle(selectedMatch?.asset)}
              />
            )
          }
        />

        <br />

        <MatchFlaggedContentSection
          assetSrcs={assetSrcs}
          selectedMatch={selectedMatch}
          selectedReport={selectedReport}
        />

        <MatchSelector
          handleUpdateSelectedMatch={handleUpdateSelectedMatch}
          handleUpdateSelectedNfts={handleUpdateSelectedNfts}
          selectedMatchIndex={selectedMatchIndex}
          selectedNfts={selectedNfts}
          srcs={counterfeitSrcs}
        />

        <br></br>

        <Heading as="h4" fontSize={13} maxW="75%" noOfLines={1} size="sm">
          Collection
        </Heading>

        <Divider maxWidth={comparisonDividerWidth} padding={1} />

        <DetailRow
          leftDetail={
            <Text fontSize={13}>
              {extractCollectionSlugFromNftOrCollection(
                selectedReport.flagged_collection,
              )}
            </Text>
          }
          rightDetail={
            shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
            hasOriginalImage(assetSrcs) &&
            originalCollectionHasContractAddress(selectedReport) && (
              <Text fontSize={13}>
                {extractCollectionSlugFromNftOrCollection(
                  selectedReport.original_collection,
                )}
              </Text>
            )
          }
          title="Name"
        />

        {selectedReport?.flagged_collection?.minted_at && (
          <>
            <Divider maxWidth={comparisonDividerWidth} />

            <DetailRow
              leftDetail={
                <Text fontSize={13}>
                  {getTimestampDisplay(selectedReport.flagged_collection.minted_at)}
                </Text>
              }
              rightDetail={
                shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
                selectedReport.original_collection.minted_at && (
                  <Text fontSize={13}>
                    {getTimestampDisplay(selectedReport.original_collection.minted_at)}
                  </Text>
                )
              }
              title="Mint Date"
            />
          </>
        )}

        <Divider maxWidth={comparisonDividerWidth} />

        <CollectionMarketplaceLinks
          assetSrcs={assetSrcs}
          selectedMatch={selectedMatch}
          selectedReport={selectedReport}
        />

        <Divider maxWidth={comparisonDividerWidth} />

        <DetailRow
          leftDetail={
            <DoppelLink
              display="block"
              fontSize={13}
              href={_extractContractLink(selectedReport.flagged_collection)}
              maxWidth={150}
              name={extractContractAddress(selectedReport.flagged_collection)}
            />
          }
          rightDetail={
            shouldShowRightColumn(selectedReport, selectedMatch?.asset) && (
              <DoppelLink
                display="block"
                fontSize={13}
                href={_extractContractLink(selectedReport.original_collection)}
                maxWidth={150}
                name={extractContractAddress(selectedReport.original_collection)}
              />
            )
          }
          title="Contract"
        />

        <Divider maxWidth={comparisonDividerWidth} />

        <DetailRow
          leftDetail={
            <HStack>
              <Image height="15px" src={CHAIN_TO_LOGO_URL[flaggedCollectionChain]} />

              <Text fontSize={13}>{flaggedCollectionChain}</Text>
            </HStack>
          }
          rightDetail={
            shouldShowRightColumn(selectedReport, selectedMatch?.asset) &&
            hasOriginalImage(assetSrcs) && (
              <HStack>
                <Image height="15px" src={CHAIN_TO_LOGO_URL[originalCollectionChain]} />

                <Text fontSize={13}>{originalCollectionChain}</Text>
              </HStack>
            )
          }
          title="Chain"
        />

        <Divider maxWidth={comparisonDividerWidth} />

        <DetailRow
          leftDetail={
            <DoppelLink
              display="block"
              fontSize={13}
              href={_extractCreatorLink(selectedMatch?.flagged_nft, true)}
              maxWidth={150}
              name={_extractCreatorAddress(selectedMatch?.flagged_nft, true)}
            />
          }
          rightDetail={
            <DoppelLink
              display="block"
              fontSize={13}
              href={_extractCreatorLink(selectedMatch?.asset, false)}
              maxWidth={150}
              name={_extractCreatorAddress(selectedMatch?.asset, false)}
            />
          }
          title="Creator"
        />

        <Divider maxWidth={comparisonDividerWidth} />

        <DetailRow
          leftDetail={
            <Text fontSize={13}>
              {flaggedCollectioNumItems ? flaggedCollectioNumItems : ''}
            </Text>
          }
          rightDetail={
            <Text fontSize={13}>
              {originalCollectionNumItems ? originalCollectionNumItems : ''}
            </Text>
          }
          title="# Items"
        />

        <Divider maxWidth={comparisonDividerWidth} />
      </Flex>

      <Flex direction="column" padding="24px" paddingTop="6px" width="548px">
        <Heading as="h4" marginBottom={1} size="md">
          Alert Breakdown
        </Heading>

        <Text fontSize={12} marginBottom={2}>
          {getTimestampDisplay(firstMatch?.created_at)}
        </Text>

        <HStack marginBottom={5}>
          <Tag fontSize={12} height="fit-content" width="fit-content">
            {_extractReportType(selectedReport)}
          </Tag>

          <Tag fontSize={12} height="fit-content" width="fit-content">
            {getStatusLabel(selectedReport.status, ReportType.NFTS)}
          </Tag>
        </HStack>

        <Divider maxWidth="90%" />

        <>
          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <Tag fontSize={12}>
                {isEmployeeView || isDisneyOrgId(orgId) ? (
                  <HStack>
                    <Editable
                      defaultValue={selectedReport.max_similarity_score.toFixed(2)}
                      key={'max_similarity_score_editor_' + selectedReport.id}
                      onSubmit={(value: string) =>
                        updateMaxSimilarityScoreForReportMutation({
                          variables: {
                            id: selectedReport.id,
                            max_similarity_score: parseFloat(value),
                            report_change: {
                              type: 'max_similarity_score',
                              collection_report_id: selectedReport.id,
                              new_value: value,
                              old_value: selectedReport.max_similarity_score.toString(),
                              user_id: userId,
                            },
                          },
                        })
                      }
                    >
                      <EditablePreview key="max_similarity_score_editor_preview" />

                      <EditableInput key="max_similarity_score_editor_input" />
                    </Editable>

                    <Image height="8px" src={DOPPEL_EDIT_ICON} />
                  </HStack>
                ) : (
                  selectedReport.max_similarity_score.toFixed(2)
                )}
              </Tag>
            }
            title="Max Similarity Score"
            tooltipText="The highest similarity score of any NFT in the flagged collection."
          />

          <Divider maxWidth="90%" />
        </>
        {!isDisneyOrgId(orgId) && (
          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <DoppelScore score={selectedReport.importance_score.toFixed(2)}>
                {isEmployeeView && (
                  <HStack>
                    <Editable
                      defaultValue={selectedReport.importance_score?.toFixed(2)}
                      key={'importance_score_editor_' + selectedReport.id}
                      onSubmit={(value: string) =>
                        updateImportanceScoreForReportMutation({
                          variables: {
                            id: selectedReport.id,
                            importance_score: parseFloat(value),
                            report_change: {
                              type: 'importance_score',
                              collection_report_id: selectedReport.id,
                              new_value: value,
                              old_value: selectedReport.importance_score?.toString(),
                              user_id: userId,
                            },
                          },
                        })
                      }
                    >
                      <EditablePreview key="importance_score_editor_preview" />

                      <EditableInput key="importance_score_editor_input" />
                    </Editable>

                    <Image height="8px" src={DOPPEL_EDIT_ICON} />
                  </HStack>
                )}
              </DoppelScore>
            }
            title={'Priority Score'}
          />
        )}

        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>
              {convertCryptoToDollars(
                selectedReport.flagged_collection?.volume_traded_opensea,
                extractChainFromNftOrCollection(selectedReport.flagged_collection),
              )}
            </Text>
          }
          title="Volume Traded"
          tooltipText="The amount of $$$ that has been traded for the entire flagged collection."
        />

        {isNftVolumeEnabled(orgId) && (
          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={<Text fontSize={13}>{convertToDollarAmount(usdVolume)}</Text>}
            title="Flagged NFT Volume Traded"
            tooltipText="The amount of $$$ that has been traded for the flagged NFTs in the given collection."
          />
        )}

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>
              {convertCryptoToDollars(
                selectedReport.flagged_collection?.floor_price_opensea,
                extractChainFromNftOrCollection(selectedReport.flagged_collection),
              )}
            </Text>
          }
          title="Floor Price"
          tooltipText="The listing price for the cheapest item in the flagged collection."
        />

        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={<Text fontSize={13}>{matchCount}</Text>}
          title="# Matches"
          tooltipText="The number of matches for this entire collection."
        />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>{convertToDollarAmount(estimatedValue)}</Text>
          }
          title="Estimated Value"
          tooltipText="The total estimated value of all the matches in this alert."
        />

        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Flex>
              {(selectedReport.match_types &&
              Object.keys(selectedReport.match_types).length > 0
                ? Object.keys(selectedReport.match_types)
                : getMatchTypes(selectedReport.collection_reports_to_matches)
              ).map((type) => (
                <Tag
                  fontSize={12}
                  height="fit-content"
                  key={'detail_row_' + type}
                  marginRight={2}
                  width="fit-content"
                >
                  {type}
                </Tag>
              ))}
            </Flex>
          }
          title="Match Type"
          tooltipText="The type of match that was found. Eg. image, keyword, etc."
        />

        {(selectedReport?.status == ReportStatus.REPORTED ||
          selectedReport?.report_status == ReportStatus.RESOLVED ||
          selectedReport?.report_status == ReportStatus.APPROVED_DERIVATIVE) && (
          <EnforcementStatuses
            client={client}
            enforcementRequests={enforcementRequestsData?.enforcement_requests}
            isEnforcementRequestLoading={isEnforcementRequestLoading}
            onEnforcementUpload={() => {
              setIsEnforcementUpload(true)
              setEnforcementModalOpen(true)
            }}
            openRetractionForm={null}
            openRetractionScreenshotModal={null}
            product={ProductType.NFT}
            setShouldShowEnforcementDetails={setShouldShowEnforcementDetails}
          />
        )}

        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            fetchingStateChanges ? (
              <Spinner size="sm" />
            ) : (
              <ReportHistory stateChanges={reportStatusChanges} />
            )
          }
          title="Audit Logs"
          tooltipText="The audit trail for this alert."
        />

        <Divider maxWidth="90%" />

        <Spacer />

        <DetailNotes notes={selectedReport?.notes} onSave={onSaveNotes} />

        <Box>
          <Flex flexWrap="wrap" justify={'flex-end'}>
            {getAllowedActionButtons(selectedReport.status, isEmployeeView).map(
              (status: ReportStatus) => (
                <ReportDetailButton
                  currentStatus={selectedReport.status}
                  isNft={true}
                  key={'report_detail_button_' + status}
                  reportAction={status}
                  setReportFormOpen={setEnforcementModalOpen}
                  shouldShowLoadingState={true}
                  statusIsEnum={false}
                  updateReportStatusMutation={setReportStatus(status)}
                />
              ),
            )}
          </Flex>
        </Box>

        <HStack mt="8px">
          {footerComponent}

          <Spacer />
        </HStack>
      </Flex>
    </>
  )
}

export function originalCollectionHasContractAddress(selectedReport) {
  return selectedReport.original_collection?.contract_address != null
}

export function assetTypeNotCollectionRelated(asset) {
  if (!asset) return true
  return (
    asset.type != AssetType.CollectionBanner &&
    asset.type != AssetType.CollectionImage &&
    asset.type != AssetType.CollectionProfile
  )
}

function _extractCreatorAddress(nftOrAsset, isCounterfeit) {
  const nft = isCounterfeit ? nftOrAsset : nftOrAsset?.nft

  if (!nft?.creator_address) return ''

  return nft?.creator_address
}

function _extractCreatorLink(nftOrAsset, isCounterfeit) {
  const nft = isCounterfeit ? nftOrAsset : nftOrAsset?.nft

  if (!nft?.creator_address) return ''

  return CHAIN_ADDRESSES[nft?.chain] + nft?.creator_address
}

export function extractContractAddress(nftOrReport) {
  if (!nftOrReport) return ''
  return nftOrReport.contract_address
}

function _extractContractLink(collection) {
  const chain = extractChainFromNftOrCollection(collection)

  return CHAIN_ADDRESSES[chain] + extractContractAddress(collection)
}

function extractFlaggedTitle(selectedReport) {
  return extractCollectionSlugFromNftOrCollection(selectedReport.flagged_collection)
}

function _extractOriginalTitle(asset) {
  return extractCollectionSlugFromNftOrCollection(asset?.collection)
}

function _extractFlaggedSubtitle(selectedReport) {
  return 'Flagged NFT Collection'
}

function _extractReportType(selectedReport) {
  return (
    selectedReport.report_type.slice(0, 1) +
    selectedReport.report_type.slice(1, selectedReport.report_type.length).toLowerCase()
  )
}

function _extractOriginalSubtitle(selectedReport, asset) {
  if (!asset?.type) return 'Original'
  const assetType = asset?.type
  if (assetType === AssetType.NFT) {
    return 'Original NFT Collection'
  } else {
    return 'Original Brand IP'
  }
}

export function shouldShowRightColumn(selectedReport, asset): boolean {
  return true
}

export function hasOriginalImage(assetSrc): boolean {
  return Boolean(assetSrc && assetSrc[0])
}

export function extractTokenId(nft) {
  if (!nft) return ''
  return nft.token_id
}

export function extractTitleFromNft(nft) {
  return nft ? nft.title : ''
}

export function extractCurrentPriceFromNft(nft) {
  return nft ? nft.current_price : ''
}

export function mapFlaggedContentTypeToReadable(
  flagged_content_type: AssetType | undefined,
): string {
  switch (flagged_content_type) {
    case AssetType.CollectionBanner:
      return 'Banner'
    case AssetType.CollectionProfile:
      return 'Featured'
    default:
      return 'Profile'
  }
}

export function expandChainShorthand(chain) {
  if (chain === 'eth') return 'ethereum'
  return chain
}

export default withApollo<any>(CounterfeitDetail)
